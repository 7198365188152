import React from "react";

const Navbar = () => {
  return (
    <>
      <h1 style={{ textAlign: "end", padding: "1rem" }}>ADMIN</h1>
    </>
  );
};

export default Navbar;
