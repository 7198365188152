// import React, { useState } from "react";
// import Layout from "../../layout/Main/Layout";
// import Graph from "../../components/Graph/Graph";
// import styles from "./Home.module.css";
// import MonthYearSelect from "../../components/MonthYear/MonthYearSelect";
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// const Home = () => {
//   const currentDate = new Date();
//   const initialMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//   const initialYear = currentDate.getFullYear().toString();

//   const [selectedMonth, setSelectedMonth] = useState(initialMonth);
//   const [selectedYear, setSelectedYear] = useState(initialYear);
//   const [chartType, setChartType] = useState("BAR");

//   const handleMonthYearChange = (month, year) => {
//     setSelectedMonth(month);
//     setSelectedYear(year);
//   };
//   const handleChartTypeChange = (event) => {
//     setChartType(event.target.value);
//   };

//   console.log("sele", chartType);

//   return (
//     <>
//       <Layout>
//         <div
//           style={{
//             padding: "0 1rem",
//             display: "flex",
//             justifyContent: "space-between",
//           }}
//         >
//           <MonthYearSelect
//             onMonthYearChange={handleMonthYearChange}
//             selectedMonth={selectedMonth}
//             selectedYear={selectedYear}
//           />
//           <FormControl
//             variant="outlined"
//             sx={{ minWidth: 180, margin: "1rem" }}
//           >
//             <InputLabel htmlFor="chartType">Select Chart Type</InputLabel>
//             <Select
//               label="Select Chart Type"
//               value={chartType}
//               onChange={handleChartTypeChange}
//               id="chartType"
//             >
//               <MenuItem value="BAR">Bar Chart</MenuItem>
//               <MenuItem value="LINE">Line Chart</MenuItem>
//             </Select>
//           </FormControl>
//         </div>
//         <div
//           style={{
//             border: "2px solid darkgrey",
//             height: "0",
//           }}
//         />
//         <div className={styles.main_div}>
//           <div className={styles.graph_div}>
//             <Graph
//               title="BUDDY Added"
//               fetchData={`/admin/dashboard/user-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div>
//           <div className={styles.graph_div}>
//             <Graph
//               title="EXPLORER Added"
//               fetchData={`/admin/dashboard/vendor-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div>
//           {/* <div className={styles.graph_div}>
//             <Graph
//               title="Events Added"
//               fetchData={`/admin/dashboard/event-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div>
//           <div className={styles.graph_div}>
//             <Graph
//               title="Venue Added"
//               fetchData={`/admin/dashboard/venue-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div>
//           <div className={styles.graph_div}>
//             <Graph
//               title="Tickets Purchased"
//               fetchData={`/admin/dashboard/ticket-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div>
//           <div className={styles.graph_div}>
//             <Graph
//               title="Daily User Engagement"
//               fetchData={`/admin/dashboard/user-activity-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div>
//           <div className={styles.graph_div}>
//             <Graph
//               title="Daily Vendor Engagement"
//               fetchData={`/admin/dashboard/vendor-activity-data?month=${parseInt(
//                 selectedMonth
//               )}&year=${parseInt(selectedYear)}`}
//               chartType={chartType}
//             />
//           </div> */}
//         </div>
//       </Layout>
//     </>
//   );
// };

// export default Home;



import React, { useState, useEffect } from "react";
import Layout from "../../layout/Main/Layout";
import Graph from "../../components/Graph/Graph";
import styles from "./Home.module.css";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { get } from "../../config/axios";

const Home = () => {
  const currentDate = new Date().toISOString().slice(0, 10); // Format as YYYY-MM-DD

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [chartType, setChartType] = useState("BAR");
  const [buddyData, setBuddyData] = useState([]);
  const [explorerData, setExplorerData] = useState([]);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const fetchGraphData = async (startDate, endDate, userType) => {
    try {
      const response = await get(`/api/dashboard/dashUser/getAllGraphUsers?startDate=${startDate}&endDate=${endDate}&userType=${userType}`);
      console.log("response", response);
      // debugger;
      return response?.monthlyData || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

 

  const fetchData = async () => {
    const buddyData = await fetchGraphData(startDate, endDate, "BUDDY");
    console.log("buddydata",buddyData);
    const explorerData = await fetchGraphData(startDate, endDate, "EXPLORER");
    console.log("exploredata",explorerData);
    setBuddyData(buddyData);
    setExplorerData(explorerData);
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]); // Fetch data initially and whenever startDate or endDate changes

  return (
    <>
      <Layout>
        <div
          style={{
            padding: "0 1rem",
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ margin: "1rem" }}
          />
          -
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ margin: "1rem" }}
          />
          <FormControl
            variant="outlined"
            sx={{ minWidth: 180, margin: "1rem" }}
          >
            <InputLabel htmlFor="chartType">Select Chart Type</InputLabel>
            <Select
              label="Select Chart Type"
              value={chartType}
              onChange={handleChartTypeChange}
              id="chartType"
            >
              <MenuItem value="BAR">Bar Chart</MenuItem>
              <MenuItem value="LINE">Line Chart</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            border: "2px solid darkgrey",
            height: "0",
          }}
        />
        <div className={styles.main_div}>
          <div className={styles.graph_div}>
            <Graph
              title="BUDDY Added"
              data={buddyData}
              chartType={chartType}
            />
          </div>
          <div className={styles.graph_div}>
            <Graph
              title="EXPLORER Added"
              data={explorerData}
              chartType={chartType}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
