export const transactionTableColumn=[
    {
        id: "S.No",
        label: "S.No",
        minWidth: 70,
        align: "left",
    },
    {
        id: "explorerId",
        label: "Explorer Name",
        minWidth: 70,
        align: "center",
    },
    {
        id: "tokens",
        label: "Tokens",
        minWidth: 70,
        align: "center",
    },
    {
        id: "comment",
        label: "Comment",
        minWidth: 70,
        align: "center",
    },
    {
        id: "transactionType",
        label: "Transaction Type",
        minWidth: 70,
        align: "center",
    },
    {
        id: "createdAt",
        label: "Date",
        minWidth: 70,
        align: "center",
    },
]