import React, { useEffect, useState } from "react";
import { get } from "../../config/axios";
import Layout from "../../layout/Main/Layout";
import { Typography } from "@mui/material";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { transactionTableColumn } from "../../constants/transactionPage";
const TransactionManagement=()=>{
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(true);
    const [transactionData,setTransactionData]=useState([])
    const fetchTransaction=async()=>{
        try {
            setLoading(true)
            const res=await get('/api/dashboard/features/transactions')
            setTransactionData(res.data)
            console.log(res)
            setLoading(false)
            setPageCount(res?.totalPage);
        } catch (err) {
            console.log("Error",err)
            setLoading(true)
        }
    }
    useEffect(()=>{
        fetchTransaction()
    },[])
    const handleChange=(page)=>{
        setPage(page)
    }
    return (
        <div>
         <Layout>
            <div style={{ padding: "1rem" }}>
              <Typography variant="h5">Transaction History</Typography>
              <CustomTable
                data={transactionData}
                columns={transactionTableColumn}
                handleEdit={()=>{}}
                handleDelete={()=>{}}
                handleStatus={()=>{}}
                handleActive={() => {}}
                handlePageChange={(page) => handleChange(page)}
                pageNumber={page}
                pageCount={pageCount}
                loading={loading}
            />
            </div>
          </Layout>
        </div>
    )
}
export default TransactionManagement;