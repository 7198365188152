// import { useEffect, useState } from 'react';

// import CircularProgress from '@mui/material/CircularProgress';
// import ListofNotifications from './ListofNotifications';
// import ScheduleInputNotification from './ScheduleInputNotification';
// //import { callAxios } from '../../../utils/axios';
// // Table Imports
// import { toastMessage } from '../../utils/toastMessage';
// import { get } from '../../config/axios';
// import React from 'react';
// import Layout from '../../layout/Main/Layout';

// const Notifications = () => {
//  // console.log('In Notification')
//  // console.log('In No')
//   const [loading, setLoading] = useState(false);
//   const [message,setMessage]=useState('')
//   const [searchText, setSearchText] = useState('');
//   const [allNotifications, setAllNotifications] = useState([]);

//   const getAllNotification = async () => {
//     try {
//       setLoading(true);
//       // const { data } = await callAxios(
//       //   'get',
//       //   `dashboard/notifications/getNotifications?search=${searchText}`
//       // );
//       // const {data}=await get('/notifications/getNotifications?search=${searchText}')
//       console.log("In get All Notification")
//       const res=await get('/api/dashboard/features/getNotifications')
//       console.log(res)
//       console.log( typeof res.data);
//       setAllNotifications( res.data);
//       //   setTotalPages(data.totalPages);
//       //   setCount(data.count);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       toastMessage(error.message, 'error');
//     }
//   };
//  console.log(allNotifications)
//   useEffect(() => {
//     getAllNotification();
//   }, [searchText,message]);
//   return (
//     <>
//     <Layout>
//       <div className="w-full flex flex-col gap-6 ">
//         <div className="w-full flex flex-col gap-2">
//           <h2 className="publicSans text-2xl font-bold leading-8 tracking-wide text-tertiary pl-2">
//             Notification
//           </h2>
//           <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium pl-2">
//             Manage Notification
//           </p>
//         </div>
//         <div className="w-full bg-white rounded-lg shadow p-4 px-8 flex justify-between gap-2 ml-2 mr-5">
//           <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
//             <img src="/assets/icons/search.svg" alt="" className="" />
//             <input
//               type="text"
//               placeholder="Search"
//               className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
//               value={searchText}
//               onChange={(e) => setSearchText(e.target.value)}
//             /> 
//           </div>
//         </div>
//          <div className="flex gap-4">
//           <div className="w-2/5 h-72 flex flex-col py-2 gap-2 px-2 ">
//             <ScheduleInputNotification
//               setAllNotifications={setAllNotifications} allNotifications={allNotifications} setMessage={setMessage}
//             />
//           </div>
//           <div className="w-3/5 flex flex-col py-2 gap-2 px-2">
//             {loading ? (
//               <div className="w-full flex justify-center items-center">
//                 <CircularProgress color="secondary" />
//               </div>
//             ) : (
//               <ListofNotifications
//                 allNotifications={allNotifications}
//                 setAllNotifications={setAllNotifications}
//               />
//             )}
//           </div>
//         </div> 
//       </div>  
//     </Layout>
//     </>
//   );
//   // return (
//   //   <div className="w-screen h-screen flex justify-center items-center">
//   //     <div className="w-1/2 flex flex-col gap-6">
//   //       <div className="w-full flex flex-col gap-2">
//   //         <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
//   //           Notification
//   //         </h6>
//   //         <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
//   //           Manage Notification
//   //         </p>
//   //       </div>
//   //       <div className="w-full bg-white rounded-lg shadow p-4 px-8 flex justify-between gap-2">
//   //         <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4">
//   //           <img src="/assets/icons/search.svg" alt="" className="" />
//   //           <input
//   //             type="text"
//   //             placeholder="Search"
//   //             className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm"
//   //             value={searchText}
//   //             onChange={(e) => setSearchText(e.target.value)}
//   //           />
//   //         </div>
//   //       </div>
//   //        <div className="flex gap-4">
//   //         <div className="w-2/5 h-72 flex flex-col py-2 gap-2 px-2">
//   //           <ScheduleInputNotification
//   //             setAllNotifications={setAllNotifications}
//   //             allNotifications={allNotifications}
//   //           />
//   //         </div>
//   //         <div className="w-3/5 flex flex-col py-2 gap-2 px-2">
//   //           {loading ? (
//   //             <div className="w-full flex justify-center items-center">
//   //               <CircularProgress color="secondary" />
//   //             </div>
//   //           ) : (
//   //             <ListofNotifications
//   //               allNotifications={allNotifications}
//   //               setAllNotifications={setAllNotifications}
//   //             />
//   //           )}
//   //         </div>
//   //       </div> 
//   //     </div>
//   //   </div>
//   // );

// };

// export default Notifications;



import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import ScheduleInputNotification from './ScheduleInputNotification';
import { toastMessage } from '../../utils/toastMessage';
import { get, put } from '../../config/axios';
import Layout from '../../layout/Main/Layout';
import moment from 'moment';

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [allNotifications, setAllNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [message, setMessage] = useState('')

  // Function to fetch notifications based on pagination
  const getAllNotification = async (page = 1) => {
    try {
      setLoading(true);
      // debugger
      const limit = 10; // Number of notifications per page
      const res = await get(`/api/dashboard/features/getNotifications?page=${page}&limit=${limit}`);
      console.log("In get All Notification", res.data);
      setAllNotifications(res.data);
      setTotalPages(res.totalPage);
      setCurrentPage(res.currentPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, 'error');
    }
  };

  // Function to cancel a notification
  const cancelNotification = async (id) => {
    try {
      setLoading(true);
      const { data } = await put(`/notifications/updateNotification?id=${id}`);
      toastMessage("Notification Cancelled Successfully", "success");
      setAllNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification._id === id ? data?.data : notification
        )
      );
    } catch (error) {
      setLoading(false);
      toastMessage(error?.response?.data?.message || "Something went wrong", "error");
    }
  };

  useEffect(() => {
    getAllNotification();
  }, []); // Fetch notifications on component mount

  // Function to handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getAllNotification(page);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Layout>
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h2 className="publicSans text-2xl font-bold leading-8 tracking-wide text-tertiary pl-2">
            Notification
          </h2>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium pl-2">
            Manage Notification
          </p>
        </div>
        <div className="w-full bg-white rounded-lg shadow p-4 px-8 flex justify-between gap-2 ml-2 mr-5">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-2/5 h-72 flex flex-col py-2 gap-2 px-2 ">
            <ScheduleInputNotification
              setAllNotifications={setAllNotifications}
              allNotifications={allNotifications}
              setMessage={setMessage}
            />
          </div>
          <div className="w-3/5 flex flex-col py-2 gap-2 px-2">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                {allNotifications?.map(notification => (
                  <div
                    key={notification._id}
                    className="w-full flex flex-col bg-white rounded-lg shadow p-4 "
                  >
                    <div className="flex flex-row justify-between">
                      <div>
                        <p className="block text-cyan-600 text-sm font-medium ">
                          {moment(notification?.createdAt).format(
                            "h:mm A D MMMM YYYY"
                          )}
                        </p>
                        <h6 className="text-[#093147] publicSans text-lg font-semibold">
                          {notification?.title}✨
                        </h6>
                      </div>
                      <div className="flex gap-2">
                        <button
                          className={`w-36 border-2 border-solid rounded-lg px-2 ${notification?.sent
                              ? "bg-green-100  border-green-500"
                              : "bg-yellow-100  border-yellow-500"
                            } `}
                        >
                          {notification?.sent ? "Pushed" : "Scheduled"}
                        </button>
                        <button className="w-36 bg-cyan-100  border-cyan-500 border-2 border-solid rounded-lg px-4 py-1">
                          {"Notification"}
                        </button>
                      </div>
                    </div>
                    <div className="min-h-[80px] py-2 mr-20 text-gray-700 tex">
                      {notification?.description}
                    </div>
                    {!notification?.sent && (
                      <div className="flex justify-end">
                        {notification?.deleted ? (
                          <p className="bg-red-200 text-gray-100 border-red-500 border-2 border-solid rounded-3xl px-6 py-2">
                            Deleted
                          </p>
                        ) : (
                          <button
                            className="bg-red-200 text-gray-100 border-red-500 border-2 border-solid rounded-3xl px-6 py-2"
                            onClick={() => cancelNotification(notification?._id)}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {/* Pagination */}
                <div className="flex justify-center mt-4">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
