export const coinPackColumn=[
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "name",
    label: "name",
    minWidth: 70,
    align: "center",
  },
  {
    id: "description",
    label: "description",
    minWidth: 70,
    align: "center",
  },
  {
    id: "coins",
    label: "coins",
    minWidth: 70,
    align: "center",
  },
  {
    id: "image",
    label: "image",
    type:"IMAGE",
    minWidth: 70,
    align: "center",
  },
  {
    id: "price",
    label: "price",
    minWidth: 70,
    align: "center",
  },
]
export const coinPackFormFields = [
  {
    name: "name",
    label: "name",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "description",
    type: "description",
    required: true,
  },
  {
    name: "coins",
    label: "coins",
    type: "number",
    required: true,
  },
  {
    name: "image",
    label: "image",
    type: "file",
    required: true,
  },
]