export const adminTableColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "fullname",
    label: "Name",
    minWidth: 70,
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 70,
    align: "left",
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 70,
    align: "center",
  },
  {
    id: "address",
    label: "Address",
    minWidth: 70,
    maxWidth: 100,
    align: "center",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

export const adminFormFields = [
  { name: "fullname", label: "Full Name", type: "text", required: true },
  // {
  //   name: "gender",
  //   label: "Gender",
  //   type: "text",
  //   required: false,
  // },
  {
    name: "email",
    label: "Email",
    type: "text",
    required: true,
  },
  {
    name: "phone",
    label: "Phone No.",
    type: "text",
    required: true,
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    required: true,
  },
  // {
  //   name: "createdAt",
  //   label: "Registration Date",
  //   type: "text",
  //   required: true,
  // },
];

// export const adminformFields = (permissions) => {
//   console.log("hereinis", permissions);
//   const adminformFields = [
//     { name: "name", label: "Name", type: "text", required: true },
//     {
//       name: "phoneNumber",
//       label: "Phone Number",
//       type: "number",
//       required: true,
//     },
//     { name: "email", label: "Email", type: "email", required: true },
//     { name: "password", label: "Password", type: "password", required: true },
//     {
//       name: "role",
//       label: "Role",
//       type: "text",
//       isMultiSelect: false,
//       options: ["SUPER_ADMIN", "ADMIN"],
//       required: true,
//     },
//     {
//       name: "permissions",
//       label: "Permissions",
//       type: "text",
//       isMultiSelect: true,
//       options: permissions,
//       required: true,
//     },
//   ];

//   return adminformFields;
// };
