/* eslint-disable react-hooks/rules-of-hooks */
import React, {useState,useEffect} from "react";
import { get, post } from "../../config/axios";
import Layout from "../../layout/Main/Layout";
import { Button, Typography } from "@mui/material";
import { coinPackColumn, coinPackFormFields } from "../../constants/coinPackPage";
import CustomTable from "../../components/Custom/Table/CustomTable";
import FormModal from "../../components/Custom/FormModal/FormModal";
import { postFiles } from "../../config/axios";

const CoinPacks=()=>{
    console.log('In coin packs')
    const [coinPacks,setCoinPacks]=useState([])
    const [loading,setLoading]=useState(false)
    const [message,setMessage]=useState('')
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchCoinsPack=async ()=>{
        try {
            setLoading(true)
            const res=await get("/api/dashboard/features/coin-packs")
            console.log(res)
            setCoinPacks(res.data)
            setLoading(false)
            
        } catch (err) {
            console.error("Error:", err);
            setLoading(true);     
        }
    }
    const handleChange=(page)=>{
        setPage(page)
    }
    const openModal=()=>{
        setIsModalOpen(true)
    }
    const closeModal=()=>{
        setIsModalOpen(false)
    }
    const handleSubmit=async (formData)=>{
        console.log("formData is:",formData)
        try {
            let form = new FormData();
            form.append("file", formData?.image);
            const res2 = await postFiles("/api/app/user/uploadImage", form);
            const formDataWithNumberCoins = {
                ...formData,
                coins: Number(formData.coins),
                image:res2.data.url
            };
            console.log("Converted formData is:", formDataWithNumberCoins);
            const res=await post("/api/dashboard/features/create-coin-pack",formDataWithNumberCoins)
            setMessage('Added Successfully')
            setIsModalOpen(false)
        } catch (err) {
            console.log("err",err)
            setLoading(true)
        }
    }
    useEffect(()=>{
        fetchCoinsPack()
    },[message])
    return (
        <>
        <Layout>
            <div className="flex justify-between p-2">
                <Typography variant="h5">Coin Packs</Typography>
                <Button variant="outlined" onClick={()=>openModal()}>
                 Add Coins Pack
                </Button>
            </div>
            <CustomTable
                data={coinPacks}
                columns={coinPackColumn}
                handleEdit={()=>{}}
                handleDelete={()=>{}}
                handleStatus={()=>{}}
                handleActive={()=>{}}
                handlePageChange={handleChange}
                pageNumber={page}
                pageCount={pageCount}
                loading={loading}
            />
        </Layout>
        <FormModal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            onSubmit={handleSubmit}
            fields={coinPackFormFields}
            header={"Add Coin Pack"}
            initialData={{}}
            isEditing={false}
        />
        </>
    );
};
export default CoinPacks
