/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post } from "../../config/axios";
import { Typography } from "@mui/material";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";
import { BuddyFormFields, BuddytableColumns } from "../../constants/BuddyPage";

const Buddy = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 1000);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [noDataFound, setNoDataFound] = useState(false);

  const fetchUsers = async (searchValue) => {
    console.log(searchValue);
    setLoading(true);
    await get(
      `/api/dashboard/dashUser/getAllAppUsers?page=${page}&limit=${10}&search=${searchValue}&userType=BUDDY`
    )
      .then((res) => {
        console.log("response data of Users ->", res?.data)
        if (res.data.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
          setUsers(
            res?.data.map((item) => ({
              ...item,
              action: { edit: true, delete: false },
            }))
          );
          setLoading(false);
          setPageCount(res?.totalPage);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(true);
      });
  };

  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const handleEdit = (row) => {
    // Implement the edit action for the selected row
    openModal("edit", row);
  };

  const handleDelete = (row) => {
    setDeleteUser(row);
    setDeleteModalOpen(true);
  };

  const handleDeleteUser = async (row) => {
    let url = `/api/dashboard/dashUser/updateAppUser/?id=${row._id}`;
    let response = await deleteAPI(url);
    console.log("response", response);
    setDeleteModalOpen(false);
  };

  const handleStatus = (row) => {
    // Implement the status chnage for the selected row
    console.log("Delete clicked for row34:", row);
  };

  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/dashUser/updateAppUser/?id=${id}`,
      {
        active: active,
      }
    );
    setMessage(response.message);
    toastMessage(response.message, "success");
    setLoading(false);
    fetchUsers("");
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = async (formData, isEditing, id) => {
    setLoading(true);
    try {
      if (isEditing) {
        const { ...data } = formData;
        console.log(data);
        let response = await put(`/api/dashboard/dashUser/updateAppUser?id=${id}`, data);
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        formData = {
          ...formData,
        };
        const { ...data } = formData;

        await post("", { data });
        setMessage("Successfully added");
        setIsModalOpen(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Buddy</Typography>
          <Searchbar
            search={handleSearch}
            placeholder={"Seach by name"}
            debounceTime={1000}
          />
          {!noDataFound ? (
            <CustomTable
              data={users}
              columns={BuddytableColumns}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleStatus={handleStatus}
              handleActive={(row, active) => handleActive(row, active)}
              handlePageChange={(page) => handleChange(page)}
              pageNumber={page}
              pageCount={pageCount}
              loading={loading}
            />
          ) : (
            <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
              No matching data found.
            </Typography>
          )}
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
      <FormModal
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={BuddyFormFields}
        header={editModal ? "Edit Buddy" : "Add Buddy"}
        initialData={editData}
        isEditing={editModal}
      />
    </>
  );
};

export default Buddy;
