import {
  //DatePickerElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  //TimePickerElement,
} from "react-hook-form-mui";
// import { Controller, useForm } from 'react-hook-form';
//import { DatePicker, TimePicker } from '@mui/x-date-pickers';
//import { LocalizationProvider } from '@mui/x-date-pickers';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import DateAdapter from '@date-io/date-fns';
import Loading from "react-fullscreen-loading";
//import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../utils/toastMessage";
import { useEffect, useState } from "react";
import { post } from "../../config/axios";
import React from "react";
//import DatePickerValue from "./DatePicker";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// type FormProps = {
//   subject: string;
//   date: any;
//   notificationType: string;
//   announcement: string;
// };
const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#fff",
    borderRadius: "10px",
    color: "#858D9D !important",
  },
};

const ScheduleInputNotification = ({ setAllNotifications,allNotifications,setMessage}) => {
  const [loading, setLoading] = useState(false);
  const [dateValue, setdateValue] =useState(dayjs());
  const [timeValue, settimeValue] = useState(dayjs());

  console.log("In schedule notification")

  const defaultValues = {
    subject: "",
    date: "",
    notificationType: "",
    announcement: "",
  };
  const [inputField, setInputField] = useState(defaultValues);
  const onInputChange = (e) => {
   // console.log(e.target.value);
    const { name, value } = e.target;
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onInputDateChange = (name, value) => {
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(()=>{
    onInputDateChange("date",dateValue)
  },[dateValue])
  const onInputSelectChange = (value) => {
    setInputField((prev) => ({
      ...prev,
      notificationType: value,
    }));
  };
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      // const payload = {
      //   title: values?.subject,
      //   description: values?.announcement,
      //   scheduleDateTime: new Date(values?.date?.$d).toISOString(),
      //   event: values?.notificationType,
      // };
      const payload={
        "description": "test noti",
        "title": "TESTTSTS",
        "userType": "BUDDY" // Posible Values : "BUDDY", "EXPLORER", "ALL"
      }
      // const { data } = await callAxios(
      //   "post",
      //   "dashboard/notifications/scheduleNotification",
      //   payload
      // );
      //const {data}=await post('/notifications/scheduleNotification',payload)
      console.log(values)
      const {data}=await post('/api/dashboard/features/createNotification',values)
      console.log(data);
      //console.log(allNotifications)
      //setAllNotifications((prev) => [data, ...prev]);
      setMessage("Successfully posted")
      setInputField(defaultValues);
      toastMessage("Notification Scheduled Successfully", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };
  //console.log(inputField);
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <FormContainer
        defaultValues={inputField}
        onSuccess={onSubmit}
        values={inputField}
        resetOptions={{
          keepValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepDirty: false,
          keepIsValid: false,
          keepSubmitCount: false,
        }}
        shouldFocusError={true}
      >
        <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
          <h6 className="text-tertiary publicSans text-base font-semibold ">
            Create Notification
          </h6>
          <div className="w-full ">
            <div className="w-full ">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Schedule Subject
              </p>
              <TextFieldElement
                name="subject"
                value={inputField?.subject}
                onChange={onInputChange}
                fullWidth
                size="small"
                placeholder="Subject"
                sx={styles.root}
                required
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-2 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Date
              </p>
              {/*<DatePicker
                  name="date"
                  inputProps={{
                    size: "small",
                    fullWidth: true,
                  }}
                  disablePast={true}
                  defaultValue={inputField?.date}
                  onChange={(value) => onInputDateChange("date", value)}
                  sx={styles.root}
                  required
                /> */}
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                          label="Date picker"
                          value={dateValue}
                          onChange={(newValue) => setdateValue(newValue)}
                      />
                  </DemoContainer>
              </LocalizationProvider> 
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Time
              </p>
                {/* <TimePicker
                  name="date"
                  required
                  inputProps={{
                    sx: styles.root,
                    size: "small",
                  }}
                  disablePast={
                    inputField?.date === "" || inputField?.date === null
                      ? false
                      : new Date(inputField?.date) > new Date()
                      ? false
                      : true
                  }
                  defaultValue={inputField?.date}
                  onChange={(value) => onInputDateChange("date", value)}
                />  */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                  label="Time picker"
                  value={timeValue}
                  onChange={(timeValue) => settimeValue(timeValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
             
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Notification Type
              </p>
              <SelectElement
                options={[
                  {
                    id: "BUDDY",
                    label: "BUDDY",
                  },
                  {
                    id: "EXPLORER",
                    label: "EXPLORER",
                  },
                  {
                    id: "ALL",
                    label: "ALL",
                  },
                ]}
                name="notificationType"
                fullWidth
                size="small"
                placeholder="Notification Type"
                sx={styles.root}
                value={inputField?.notificationType}
                onChange={(value) => onInputSelectChange(value)}
                required
              />
            </div>
          </div>

          <div className="w-full ">
            <div className="w-full ">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Announcements
              </p>
              <TextFieldElement
                rows={4}
                multiline
                name="announcement"
                fullWidth
                size="small"
                placeholder="Subject"
                sx={styles.root}
                required
                value={inputField?.announcement}
                onChange={onInputChange}
              />
            </div>
          </div>

          <div className="w-full ">
            <button className="w-full publicSans text-sm font-semibold leading-5 border-secondary rounded-lg p-3 text-gray-700 border-2">
              Submit
            </button>
          </div>
        </div>
      </FormContainer>
    </>
  );
};

export default ScheduleInputNotification;
